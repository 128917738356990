<template>
<div>
  <b-skeleton-table
    v-if="loading_data"
    :rows="5"
    :columns="4"
    :table-props="{ bordered: true, striped: true }"
  ></b-skeleton-table>
  <b-table v-else
        :key="$store.getters['user/getLang']"
        :per-page="perPage"
        :current-page="currentPage"
        ref="refUserListTable"
        class="position-relative"
        :items="raices_data"
        responsive
        :fields="tableColumns"
        sort-icon-left
        primary-key="id"
        show-empty
        :empty-text="$t('table.empty')"
        striped
      >
      <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge v-if="data.item.status == null"
            pill
            variant="light-warning"
            class="text-capitalize"
          >
            Incompleto
          </b-badge>
          <b-badge v-else
            pill
            :variant="`light-${data.item.status == 'KO'?'danger':'success'}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <!-- Column: action -->
        <template #cell(accion)="data">
          <b-badge v-if="data.item.accion == 'crear'" pill class="text-capitalize" variant="light-success">
          {{ data.item.accion }}
          </b-badge>
          <b-badge v-if="data.item.accion == 'actualizar'" pill class="text-capitalize" variant="light-warning">
          {{ data.item.accion }}
          </b-badge>
          <b-badge v-if="data.item.accion == 'eliminar'" pill class="text-capitalize" variant="light-danger">
            {{ data.item.accion }}
          </b-badge>
          <b-badge v-if="data.item.accion == null" pill class="text-capitalize" variant="light-danger">
            No crear
          </b-badge>
        </template>
      
  </b-table>
  <!-- Table Footer -->
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <b-form-group
            :label="$t('table.items_shown_per_page')"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              class="ml-1"
              v-model="perPage"
              size="sm"
              inline
              :options="perPageOptions"
            />
          </b-form-group>
          <div>
            <span class="text-muted">{{ $t('table.showing_entries', {
                from: dataMeta.from,
                to: dataMeta.to, 
                of: dataMeta.of
              })}}
            </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="raices_data.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
      </b-card-body>
      </div>

</template>

<script>
import {
  BAvatarGroup, BCard, BCardBody, BRow, BCol, BFormGroup,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BFormCheckbox, BFormSelect,VBTooltip,  BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
} from 'bootstrap-vue'
export default {
  components: {
    BAvatarGroup, BCard, BCardBody, VBTooltip, BRow, BCol, BFormGroup,  BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BFormCheckbox, BFormSelect, BCardHeader, BCardTitle, BBadge, BDropdown, BDropdownItem, BPagination, BSkeletonTable
  },
  props:{
    raices_data:{
      type: Array,
      required: true
    },
    loading_data:{
      type: Boolean,
      required: false,
      default: false
    },
  },
  data(){
    return {
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      perPage: 10,
      currentPage: 1,

    }
  },
  computed:{
    tableColumns(){
      return [
        { key:'id', label:'ID'  },
        { key:'raices_alumno', label: this.$t('fields.name')},
        { key:'raices_unidad', label: this.$t('fields.group')},
        { key:'raices_fnac', label: this.$t('fields.f_nac')},
        { key:'educa_correo', label:this.$t('fields.email')  },
        { key: 'status', label:this.$t('fields.status') },
        { key: 'accion', label:this.$t('fields.action') },
      ]
    },
    dataMeta(){
      let total = this.raices_data.length
      let ini = total >= 1 ? 1 : 0;
      let res = (total % this.perPage)
      let total_pages = Math.floor(total / this.perPage) +1

      let localItemsCount = this.perPage;
      if(this.currentPage == total_pages){
          localItemsCount = this.perPage + res
      }
      return {
          from: this.perPage * (this.currentPage - 1) + (ini ? 1 : 0),
          to: this.currentPage == total_pages ? total : this.perPage * (this.currentPage - 1) + (ini ? localItemsCount:0),
          of: total,
      }
    },

  }
}
</script>

<style>

</style>