<template>
  <div v-if="!loading_inicial_data">
    <custom-breadcrumb :pageTitle="$t('manageSchools.raices.title')" :breadcrumb="[
      {
        text: $t('Schools'),
        to: 'schools'
      },
      {
        text: school.name,
        to: 'school-view',
        params: { id: school.id }
      },
      {
        text: $t('manageSchools.handle'),
        to: 'school-manage',
        params: { id: school.id }
      },
      {
        text: $t('manageSchools.raices.title'),
        active: true,
      }
    ]"></custom-breadcrumb>
    <form-wizard v-if="!process_finished && !process_not_allowed && school_id" color="#87bced" :title="null"
      :subtitle="null" shape="square" no-button :finish-button-text="$t('form_wizard.finish_button')"
      :back-button-text="$t('form_wizard.back_button')" :next-button-text="$t('form_wizard.next_button')" class="mb-3"
      @on-complete="formSubmitted">
      <!-- Cargar Documentos tab -->
      <tab-content :title="$t('raices.upload_documents')" :before-change="validationOne">
        <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
          <b-row>

            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t('raices.upload_documents') }}
              </h5>
              <small class="text-muted">
                {{ $t('raices.select_documents') }}
              </small>
            </b-col>
            <b-col md="6">
              <div
                :class="[show_raices_error ? 'dropbox-hasnt-file' : isSaving ? 'dropbox-is-saving' : doc_raices ? 'dropbox-has-file' : 'dropbox']">
                <input type="file" name="raicesFile" :disabled="isSaving"
                  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  class="input-file">
                <p v-if="isInitial && !doc_raices" v-html="$t('raices.raices_description', { doc_type: 'Raices' })
                  "></p>
                <p v-if="isSaving">
                  {{ $t('raices.uploading_document') }}...
                  <b-spinner variant="primary" label="Text Centered" />
                </p>
                <p v-else-if="doc_raices">
                  {{ $t('raices.selected_document') }} <br>
                  <strong>{{ doc_raices }}</strong>

                </p>
              </div>
            </b-col>

            <b-col md="6">
              <div
                :class="[show_educa_error ? 'dropbox-hasnt-file' : isSaving ? 'dropbox-is-saving' : doc_educamadrid ? 'dropbox-has-file' : 'dropbox']">
                <input type="file" name="educaFile" :disabled="isSaving"
                  @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                  accept="text/csv" class="input-file">
                <p v-if="isInitial && !doc_educamadrid" v-html="$t('raices.raices_description', { doc_type: 'EducaMadrid' })
                  "></p>
                <p v-if="isSaving">
                  {{ $t('raices.uploading_document') }}...
                  <b-spinner variant="primary" label="Text Centered" />
                </p>
                <p v-else-if="doc_educamadrid">
                  {{ $t('raices.selected_document') }} <br>
                  <strong>{{ doc_educamadrid }}</strong>
                </p>
              </div>
            </b-col>
          </b-row>
        </form>
      </tab-content>

      <!-- Verificación de la información -->
      <tab-content :title="$t('raices.validate_information')" :before-change="validationFormInfo">
        <validation-observer ref="infoRules" tag="form">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">
                {{ $t('raices.validate_information') }}
              </h5>
              <small class="text-muted">{{ $t('raices.validate_information_description') }}</small>
            </b-col>
            <b-col cols="12">
              <raices-data-table :raices_data="raices_data_unido" :loading_data="loading_data" />
            </b-col>
            <b-col cols="12" v-if="!loading_data && raices_data_unido.length > 0">
              <b-alert style="font-weight: 400;" variant="primary" show>
                <h4 class="alert-heading">
                  Por favor, verifica la información.
                </h4>
                <div class="alert-body">
                  <p>Si la información que se muestra en la verificación <b>no es correcta</b>, puedes <span
                      class="go-back-button" @click="refresh()">volver al paso anterior</span> y cargar de nuevo los
                    ficheros.</p>
                  <p>Si todos los datos son correctos y deseas continuar, debes marcar la casilla de confirmación y
                    luego pulsar en el botón "Continuar".</p>
                  <br>
                  <b-form-group label="" label-for="confirmación">
                    <validation-provider #default="{ errors }" name="confirmación" rules="required">
                      <b-form-checkbox id="confirmación" :disabled="validacion_selected" v-model="validacion_selected"
                        :state="errors.length > 0 ? false : null">
                        {{ $t('raices.confirm_information') }}
                      </b-form-checkbox>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </validation-observer>


      </tab-content>

      <!-- fin -->
      <tab-content :title="$t('raices.end_process')">
        <b-alert variant="secondary" show>
          <h4 class="alert-heading">
            {{ $t('raices.end_process') }}
          </h4>
          <div class="alert-body">
            <p v-html="$t('raices.end_process_description', { user_email: user_email })"></p>
          </div>
        </b-alert>

      </tab-content>

    </form-wizard>

    <b-alert v-if="process_not_allowed" variant="warning" show>
      <h4 class="alert-heading">
        {{ $t('raices.process_not_allowed') }}
      </h4>
      <div class="alert-body">
        <p>{{ $t('raices.process_not_allowed_description') }}</p>
      </div>
    </b-alert>
  </div>
  <div v-else class="text-center mt-2 mb-2">
    <b-spinner variant="primary" label="Text Centered" />
  </div>
</template>

<script>
import { raicesInitialData, raicesFileUpload, raicesSave } from '@/api/routes'

import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'

import RaicesDataTable from './RaicesDataTable.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BFormCheckbox,
  BAlert,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3

export default {
  components: {
    RaicesDataTable,
    CustomBreadcrumb,

    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckbox,
    BAlert,
    BButton,
    BSpinner,
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('forms.errors.required'),
    });
  },
  data() {
    return {
      doc_raices: '',
      doc_educamadrid: '',
      loading_inicial_data: true,
      process_finished: false,
      process_not_allowed: false,

      user_email: "",
      school_id: null,
      raices_data: [],
      raices_data_unido: [],
      uuid: null,
      validacion_selected: null,
      loading_data: false,
      school: {},

      // Validacion :
      required,

      currentStatus: null,

      myFormData: null,
      show_raices_error: false,
      show_educa_error: false,
    }
  },

  mounted() {
    this.getSchool();
    this.getData();
    this.reset();
    const formData = new FormData();
    this.myFormData = formData;
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.doc_raices = '';
      this.doc_educamadrid = '';
      this.raices_data = [];
      this.raices_data_unido = [];
      this.uuid = null;
      this.validacion_selected = null;
      this.loading_inicial_data = true;
      this.loading_data = false;
      this.user_email = "";
      this.process_finished = false;
      this.process_not_allowed = false;
      this.school_id = null;
    },
    getSchool() {
      if (this.$store.getters['user/getRole'] == 'school-admin') this.school = this.$store.getters['user/isSchool'];
      else {
        this.school.id = this.$route.params.id;
        this.school.name = this.$t('Detail');
      }
    },
    validate() {
      let has_errors = false
      if (!this.myFormData.has('raicesFile')) {
        this.show_raices_error = true
        has_errors = true
      }
      if (!this.myFormData.has('educaFile')) {
        this.show_educa_error = true
        has_errors = true
      }
      // console.log(has_errors)
      return !has_errors
    },
    filesChange(fieldName, fileList) {

      let thisFormData = this.myFormData
      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map(x => {
          if (fieldName == 'raicesFile') {
            if (fileList[x].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && fileList[x].type != "application/vnd.ms-excel") {
              this.makeToast('danger', this.$t('Error'), this.$t('raices.incorrect_doc_type'));
              this.currentStatus = STATUS_INITIAL;
              this.doc_raices = '';
              this.show_raices_error = true
            } else {
              thisFormData.append(fieldName, fileList[x], fileList[x].name);
              this.doc_raices = fileList[x].name
              this.show_raices_error = false
            }
          }
          if (fieldName == 'educaFile') {
            //if(fileList[x].type != "text/csv" && fileList[x].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && fileList[x].type != "application/vnd.ms-excel" ){
            //this.makeToast('danger', this.$t('Error'), this.$t('raices.incorrect_doc_type'));
            //this.currentStatus = STATUS_INITIAL;
            //this.doc_educamadrid = '';
            //this.show_educa_error = true

            //} else {
            thisFormData.append(fieldName, fileList[x], fileList[x].name);
            this.doc_educamadrid = fileList[x].name
            this.show_educa_error = false
            //} 
          }
        });

      // save it
      this.myFormData = thisFormData;
    },
    refresh() {
      this.currentStatus = STATUS_INITIAL;
      this.doc_raices = '';
      this.doc_educamadrid = '';
      const formData = new FormData();
      this.myFormData = formData;

      this.process_finished = false
      this.loading_inicial_data = true
      this.process_not_allowed = false
      setTimeout(this.getData(), 5000);
    },
    async getData() {
      await this.$http.post(raicesInitialData, {}).then(response => {
        if (response.data.status == 200) {
          this.loading_inicial_data = false
          if (response.data.school_id == 0) {
            this.school_id = this.$route.params.id;
          } else {
            this.school_id = response.data.school_id;
          }
        } else {
          this.process_not_allowed = true
          this.makeToast('danger', this.$t('Error'), response.data.msg);
          this.loading_inicial_data = false
        }

      }).catch(error => {
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      });
    },
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    formSubmitted() {
      if (this.$store.getters['user/getRole'] == 'school-admin') {
        this.$router.push('/groups');
      } else {
        this.$router.push('/schools/view/' + this.school_id);
      }
    },

    async validationOne() {
      this.myFormData.append('school_id', this.school_id);
      if (this.validate()) {
        this.validacion_selected = null;
        this.loading_data = true;
        this.currentStatus = STATUS_SAVING;
        this.makeToast('warning', this.$t('raices.processing'), this.$t('raices.processing_description'));

        try {
          const response = await this.$http.post(raicesFileUpload, this.myFormData);

          if (response.data.status === 200) {
            this.uuid = response.data.uuid;
            this.raices_data = response.data.raices_data;
            this.raices_data_unido = response.data.raices_data;

            this.loading_data = false;
            this.currentStatus = STATUS_INITIAL;
            this.doc_raices = '';
            this.doc_educamadrid = '';
            const formData = new FormData();
            this.myFormData = formData;
            return true;
          } else if (response.data.status === 400) {
            this.makeToast('danger', this.$t('Error'), this.$t(response.data.msg) + response.data.errors + '.');
            this.refresh();
            return false;
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
            this.refresh();
            return false;
          }
        } catch (err) {
          this.refresh();
          this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
          return false;
        }
      } else {
        return false;
      }
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        if (!this.loading_data && this.raices_data.length > 0) {
          this.$refs.infoRules.validate().then(success => {
            if (success) {
              this.makeToast('warning', this.$t('raices.processing'), this.$t('raices.processing_description'));

              let data = {
                uuid: this.uuid,
                raices_data: this.raices_data,
                school_id: this.school_id
              }

              this.$http.post(raicesSave, data).then(response => {
                if (response.data.status == 200) {
                  this.user_email = response.data.user_email
                  this.doc_raices = null
                  this.doc_educamadrid = null
                  this.raices_data = []
                  this.raices_data_unido = []
                  this.uuid = null
                  this.validacion_selected = null

                  resolve(true)
                } else {
                  this.makeToast('danger', this.$t('Error'), response.data.msg);
                  reject()
                }

              }).catch(error => {
                reject()
                this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));

              })
            } else {
              reject();
            }
          })
        } else {
          this.makeToast('warning', this.$t('raices.processing'), this.$t('raices.processing_description'));
          resolve(false)
        }
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.vue-form-wizard .wizard-nav-pills a,
.vue-form-wizard .wizard-nav-pills li {
  cursor: not-allowed;
  pointer-events: none;
}

.wizard-footer-left {
  display: none;
}

.go-back-button {
  color: #3a71a3;
  margin: 0 10px;
  cursor: pointer;

  &:hover {
    color: #87bced;
  }
}

.dropbox {
  outline: 2px dashed #87bced;
  /* the dash box */
  outline-offset: -10px;
  background: #87bced30;
  color: #87bced;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox-has-file {
  outline: 2px dashed #28c76f;
  /* the dash box */
  outline-offset: -10px;
  background: #e4ffc4;
  color: #28c76f;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox-hasnt-file {
  outline: 2px dashed #c73328;
  /* the dash box */
  outline-offset: -10px;
  background: #ffd8c4;
  color: #c73328;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.dropbox-is-saving {
  outline: 2px dashed gray;
  /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: gray;
  padding: 10px 10px;
  min-height: 200px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: #87bced70;
  /* when mouse over to the drop zone, change color */
}

.dropbox-is-saving p,
.dropbox-hasnt-file p,
.dropbox-has-file p,
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
